
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React, { useEffect } from "react";
import "../styles/globals.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "../apollo-client";
import analytic from "../utils/analytic";
import dynamic from "next/dynamic";
import Provider from "../context";
const WhatsappButton = dynamic(() => import("../component/common/WhatsappButton"), { ssr: false });
const TopProgressBar = dynamic(() => {
    return import("../utils/nprogress");
}, { ssr: false });
const MyApp = ({ Component, pageProps }) => {
    useEffect(() => {
        analytic.initialize();
    }, []);
    const client = useApollo();
    return (<ApolloProvider client={client}>
      <Provider>
        <TopProgressBar />
        <Component {...pageProps}/>
        <WhatsappButton />
      </Provider>
    </ApolloProvider>);
};
const __Next_Translate__Page__1933e158ee1__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__1933e158ee1__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  